import request from '../utils/request'

export function Login(data) {
    return request({
        method: "POST",
        url: "/login",
        data
    })
}

export function Register(data) {
    return request({
        method: "POST",
        url: "/register",
        data
    })
}

export function getInfo() {
    return request({
        method: "GET",
        url: "/user/info"
    })
}

// 新登录
export function login_new(data) {
    return request({
        url: '/api/login/',
        method: 'post',
        data
    })
}
// 新注册
export function register_new(data) {
    return request({
        url: '/api/register/',
        method: 'post',
        data
    })
}
// 重置密码
export function changePasswd(data) {
    return request({
        url: '/api/change_passwd/',
        method: 'post',
        data
    })
}
// 获取短信验证码
export function sendSmsCode(data) {
    return request({
        url: '/api/send_sms_code/',
        method: 'post',
        data
    })
}
