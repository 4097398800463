import { Login, getInfo, Register } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
    return {
        token: getToken(),
        name: '',
        user_id: '',
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_USERID: (state, data) => {
        state.user_id = data
    },

}

const actions = {
    // user login
    login({ commit }, userInfo) {
        const { username, password } = userInfo
        return new Promise((resolve, reject) => {
            Login({ username: username.trim(), password: password }).then(response => {
                const { data } = response
                commit('SET_TOKEN', data.token)
                setToken(data.token)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    register({ commit }, userInfo) {
        return new Promise((resolve, reject) => {
            Register(userInfo).then(response => {
                const { data } = response
                commit('SET_TOKEN', data.response_data.token)
                setToken(data.response_data.token)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // get user info
    getInfo({ commit, state }) {
        return new Promise((resolve, reject) => {
            getInfo().then(response => {
                const { data } = response

                if (!data) {
                    reject('Verification failed, please Login again.')
                }

                const { name } = data
                commit('SET_NAME', name)
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // user logout
    logout({ commit, state }) {
        removeToken() // must remove  token  first
        // resetRouter()
        commit('RESET_STATE')
        window.location.reload()
        // return new Promise((resolve, reject) => {
        //   logout(state.token).then(() => {
        //     removeToken() // must remove  token  first
        //     resetRouter()
        //     commit('RESET_STATE')
        //     resolve()
        //   }).catch(error => {
        //     reject(error)
        //   })
        // })
    },

    // remove token
    resetToken({ commit }) {
        return new Promise(resolve => {
            removeToken() // must remove  token  first
            commit('RESET_STATE')
            resolve()
        })
    },

    // 设置userInfo
    setUserInfo({ commit }, userData) {
        commit('SET_ROLES', userData.roles)
        commit('SET_NAME', userData.name)
    },

    setToken({ commit }, token) {
        commit('SET_TOKEN', token)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

