import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [

  {
    path: "/",
    name: "main",
    component: () => import('../views/main/index.vue'),
    redirect: 'home',
    meta: {
      need_login: false,
    },
    children: [
      {
        path: "home",
        name: "home",
        component: () => import('../views/home/index.vue'),
        meta: {
          need_login: false,
        }
      },
      {
        path: 'search_requirement',
        name: 'search_requirement',
        component: () => import('../views/search_requirement/index.vue'),
        meta: {
          need_login: false,
        }
      },
      {
        path: 'search_news',
        name: 'search_news',
        component: () => import('../views/search_news/index.vue'),
        meta: {
          need_login: false,
        }
      },
      {
        path: 'search_expert',
        name: 'search_expert',
        component: () => import('../views/search_expert/index.vue')
      },
      {
        path: 'search_patent',
        name: 'search_patent',
        component: () => import('../views/search_patent/index.vue'),
        meta: {
          need_login: false,
        }
      },
      {
        path: 'search_project',
        name: 'search_project',
        component: () => import('../views/search_project/index.vue'),
      },
      {
        path: 'search_engineer',
        name: 'search_engineer',
        // component: () => import('../views/search_patent/index.vue'),
        // meta: {
        //   need_login: false,
        // }
      },
      {
        path: 'news_detail',
        name: 'news_detail',
        component: () => import('@/views/search_news/detail/news.vue'),
        meta: {
          need_login: false,
        }
      },

      {
        path: 'information_detail',
        name: 'information_detail',
        component: () => import('@/views/search_news/detail/information.vue'),
        meta: {
          need_login: false,
        }
      },
      {
        path: 'law_detail',
        name: 'law_detail',
        component: () => import('@/views/search_news/detail/law.vue'),
        meta: {
          need_login: false,
        }
      },
      {
        path: 'case_detail',
        name: 'case_detail',
        component: () => import('@/views/search_news/detail/case.vue'),
        meta: {
          need_login: false,
        }
      },
      {
        path: 'article_detail',
        name: 'article_detail',
        component: () => import('@/views/search_news/detail/article.vue'),
        meta: {
          need_login: false,
        }
      },
      {
        path: 'expert_detail',
        name: 'expert_detail',
        component: () => import('@/views/search_expert/detail/index.vue'),
        meta: {
          need_login: false,
        }
      },
      {
        path: 'patent_detail',
        name: 'patent_detail',
        component: () => import('@/views/search_patent/detail/index.vue'),
        meta: {
          need_login: false,
        }
      },
      {
        path: 'requirement_detail',
        name: 'requirement_detail',
        component: () => import('@/views/search_requirement/detail/index.vue'),
        meta: {
          need_login: false,
        }
      },
      {
        path: 'survey_detail',
        name: 'survey_detail',
        component: () => import('@/views/search_requirement/detail/survey.vue'),
        meta: {
          need_login: false,
        }
      },
      {
        path: 'project_detail',
        name: 'project_detail',
        component: () => import('@/views/search_project/detail/index.vue'),
        meta: {
          need_login: false,
        }
      },
      {
        path: 'user_detail',
        name: 'user_detail',
        component: () => import('@/views/user_detail/index.vue'),
        meta: {
          need_login: true,
        }
      },
      {
        path: 'article_detail_info',
        name: 'article_detail_info',
        component: () => import('@/views/user_detail/detail/article_info.vue'),
        meta: {
          need_login: true,
        }
      },
      {
        path: 'article_detail_edit',
        name: 'article_detail_edit',
        component: () => import('@/views/user_detail/detail/article_edit.vue'),
        meta: {
          need_login: true,
        }
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import('../views/login/index.vue'),
    meta: {
      need_login: false,
    }
  }
];

const router = new VueRouter({
  // mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

export default router;
